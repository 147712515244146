import React from "react";

import NRImageLoader from "./NRImageLoader";

function NRGalleryCard(props) {

    function imageClicked(e) {
        e.preventDefault();
        props.clickEventHandler(props.imageData.id);
    }

    return (
        // <div className="gallery-card col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mx-auto">
        <div onClick={imageClicked} className="gallery-card">
            <NRImageLoader key={props.imageData.id}
                classes="gallery-card-image drop-shadows"
                src={props.imageData.sourceURL}
                alt={props.imageData.alt}
            />
        </div>
    );
};

export default NRGalleryCard;
