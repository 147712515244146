import { useParams, Outlet } from "react-router-dom";

import NRGalleryCard from "./NRGalleryCard";
import NRGalleryOverlayImage from "./NRGalleryOverlayImage";
import { getImagesByTag, getImageByID } from "../ImageList.js"



/* function for generating a gallery card given an image ID  */
function CreateGalleryCard(imageListItem) {
    return (
        <NRGalleryCard key={imageListItem.id} imageData={imageListItem} clickEventHandler={UpdateOverlay} />
    )
}


function UpdateOverlay(id) {
    //get the overlay screen, set Z value
    var overlayScreen = document.getElementById('OverlayScreen');
    overlayScreen.style.display = "block";

    //get overlay image, set src, alt
    var overlayImage = document.getElementById('OverlayImage');
    var targetImage = getImageByID(id);
    overlayImage.src = targetImage.sourceURL;
    overlayImage.alt = targetImage.alt;

    //update the caption
    var overlayCaption = document.getElementById('OverlayCaption');
    overlayCaption.innerHTML = targetImage.caption;
}

function DisableOverlay() {
    //reset the z-index of the overlay screen
    var overlayScreen = document.getElementById('OverlayScreen');
    overlayScreen.style.display = "none";
}

/* this function is used in order to sort out the page for "All" whish should not get a title */
function GetGalleryTitle(pageName) {
    /* do not add a title for the 'All' gallery page */
    if (pageName !== "All")
        return <h1 className="gallery-title">{pageName}</h1>
    else
        return <h1 className="gallery-title">{ }</h1>
}


function NRGallery() {
    let params = useParams();
    //get the list of images from the database which apply to this screen
    const GalleryImages = getImagesByTag(`${params.galleryType}`);

    return (
        <div>
            {GetGalleryTitle(params.galleryType)}

            <div id="OverlayScreen" className="screen-overlay" onClick={DisableOverlay}>
                <div className="overlay-image-container">
                    <img id="OverlayImage" src="" className="overlay-image" alt="" />
                </div>
                <h6 id="OverlayCaption" className="fullscreen-caption"></h6>
            </div>

            {/* <div className="gallery container-fluid row"> */}
            <div className="gallery-container">
                {GalleryImages.map(CreateGalleryCard)}
            </div>
        </div>
    )
}

export default NRGallery;
