
/* external libraries */
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

/* my elements */
import App from './App';
import NRHome from "./components/NRHome";
import NRGallery from "./components/NRGallery";

/* my css */
import './styles.css';


render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<NRHome />} />   {/* this is the case which applies when no sub-route is supplied (nested inside <App /> when the route is just "/") */}
                <Route path="/Gallery">
                    <Route path=":galleryType" element={<NRGallery />} />
                </Route>
                <Route path="*" element={<NRHome />} />   {/* this is the default case, it renders if an unhandled route is accessed */}
            </Route>
        </Routes>
    </BrowserRouter>
    , document.getElementById('root')
);
