import React from "react";
import {Carousel} from "react-bootstrap";
import {getImagesByTag} from "../ImageList.js"
import NRImageLoader from "./NRImageLoader";


//function for generating a carousel item given a data packet from the HomeCarouselImageList
function CreateImage( imageListItem ){
    return(
        <Carousel.Item key= {imageListItem.id}>
            <NRImageLoader key={imageListItem.id} classes="carousel-image drop-shadows" src={imageListItem.sourceURL} alt={imageListItem.alt} />
        </Carousel.Item>
    )
}

//get the list of images from the database which apply to this screen
const HomeScreenCarouselImages = getImagesByTag("homeCarousel");

function NRHomePageCarousel(){
    return (
        <section className="carousel-section section-formatting" id="home">

            <Carousel interval="4000" variant="dark">
                {HomeScreenCarouselImages.map(CreateImage)}
            </Carousel>

        </section>
    );
};

export default NRHomePageCarousel;
