
import NRImageLoader from "./NRImageLoader";

function NRAboutUs(){
    return (
        <section className="section-formatting dark-section about-us-section" id="about-us">
            <div className="container-fluid about-us-container">
                <div className="row">

                    <div className="col-xl-7 col-12 about-us-row about-us-left my-auto">
                        <div className="about-us-text">
                            <h1 className="about-us-title">Who we are</h1>
                            <div className="p about-us-description text-bare">
                                We are brothers James and Earl Nolte and the owners of Nolte Remodeling, LLC. We formed our
                                company after the passing of Mark MaGrann* and the subsequent closing of MaGrann Construction
                                Services, where for more than thirty years we honed and perfected the skills required to build
                                custom homes and to perform an extensive array of residential home renovations. Our passion,
                                expertise, attention to detail, and commitment to customer satisfaction are at the very heart of
                                Nolte Remodeling. Let us show you how easy it is to make your dream home project a reality today.
                                <br /> <br />
                                * Mark MaGrann was a dear friend and the founder of MaGrann Construction Services. Under his
                                leadership for over thirty years we developed the beliefs and principles surrounding a “Customer First
                                Attitude”. As a result, these values are deeply ingrained in Nolte Remodeling.
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12 about-us-row">
                        <NRImageLoader key="1"
                            classes="about-us-image drop-shadows"
                            src="https://i.imgur.com/r48nQln.jpg"
                            alt="bathroom built-in image"
                        />
                    </div>

                    <div className="col-xl-5 col-12 about-us-row d-none d-xl-inline-block">
                        <NRImageLoader key="2"
                            classes="about-us-image drop-shadows"
                            src="https://i.imgur.com/Mmk8I8P.jpg"
                            alt="kitchen remodel image 2"
                        />
                    </div>
                    <div className="col-xl-7 col-12 about-us-row about-us-right my-auto">
                        <div className="about-us-text">
                            <h1 className="about-us-title">What we do</h1>
                            <div className="p about-us-description text-bare">
                                As hands-on owners, we are committed to providing exceptional value throughout the process of your
                                home renovation projects. We are dedicated to providing Excellence in Design; Old World Craftsmanship
                                with Modern Materials, and Personalized Service, at every point during your project. Our high standard
                                for communication will help you navigate through the multiple stages of your project, allowing you to
                                enjoy the process. So, whether you want to replace your front entrance door, totally renovate your
                                kitchen or add a modern master suite to your home, Nolte Remodeling has the skills and experience you
                                should not only desire but expect from a professional remodeling company.
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12 about-us-row d-xl-none">
                        <NRImageLoader key="3"
                            classes="about-us-image drop-shadows"
                            src="https://i.imgur.com/Mmk8I8P.jpg"
                            alt="kitchen remodel image 2"
                        />
                    </div>

                    <div className="col-xl-7 col-12 about-us-row about-us-left my-auto">
                        <div className="about-us-text">
                            <h1 className="about-us-title">Where we operate</h1>
                            <div className="p about-us-description text-bare">
                                Our primary area of operation is most of Southern New Jersey, from shore to bridge and beyond.
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12 about-us-row">
                        <NRImageLoader key="4"
                            classes="about-us-image drop-shadows"
                            src="https://i.imgur.com/MBtSiiD.jpg"
                            alt="bar remodel image"
                        />
                    </div>

                </div>
            </div>
        </section>
    );
};

export default NRAboutUs;
