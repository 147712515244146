/* this function returns the complete set of tags to be used in the home screen menu.
    Only add items to this list which you want to see in the Gallery menu */
export function getImageTags() {
    return (
        ["All", "Bars", "Basements", "Bathrooms"
            , "Custom Cabinetry", "Kitchens", "Other"]
    )
}

const ImageList = [
    {
        /* Laura's Kitchen */
        id: 1
        , sourceURL: "https://i.imgur.com/pNnrjuR.jpg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Kitchens"
            , "All"]
    }
    , {
        /* Barrel sink bathroom */
        id: 2
        , sourceURL: "https://i.imgur.com/whan46a.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Bathrooms"
            , "All"]
    }
    , {
        /* foyer-landing built-in */
        id: 3
        , sourceURL: "https://i.imgur.com/67viWuy.jpg"
        , title: "Built-in Cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Custom Cabinetry"
            , "All"]
    }
    , {
        /* fireplace, stone surround */
        id: 4
        , sourceURL: "https://i.imgur.com/ciLR03v.jpg"
        , title: "Fireplace Remodel"
        , alt: "Fireplace remodel image"
        , caption: "Custom stone fireplace"
        , tags: ["homeCarousel"
            , "Basements"
            , "Other"
            , "All"]
    }
    , {
        /* playhouse facade built into wall */
        id: 5
        , sourceURL: "https://i.imgur.com/ohqZ78n.jpg"
        , title: "Playhouse"
        , alt: "Playhouse image"
        , caption: "Under-staircase play house"
        , tags: ["homeCarousel"
            , "Basements"
            , "Other"
            , "All"]
    }
    , {
        /* basement with stonework walls and wood floor */
        id: 6
        , sourceURL: "https://i.imgur.com/LBxJGZz.jpg"
        , title: "Basement Remodel"
        , alt: "Basement remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Basements"
            , "All"]
    }
    , {
        /* basement bar with woodwork ceiling and poker table */
        id: 7
        , sourceURL: "https://i.imgur.com/MBtSiiD.jpg"
        , title: "Basement Remodel"
        , alt: "Basement remodel image"
        , caption: ""
        , tags: ["Basements"
            , "Bars"
            , "All"]
    }
    , {
        /* Bathroom with large built-in, tile floors, and chandalier */
        id: 8
        , sourceURL: "https://i.imgur.com/r48nQln.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , tags: ["Bathrooms"
            , "Custom Cabinetry"
            , "All"]
    }
    , {
        /* Penberthy LBI Kitchen with large island  */
        id: 9
        , sourceURL: "https://i.imgur.com/Mmk8I8P.jpg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Dark double barn doors from basement remodel  */
        id: 10
        , sourceURL: "https://i.imgur.com/cOKqxQl.jpeg"
        , title: "Barn Doors"
        , alt: "Barn doors image"
        , caption: "Custom fabricated barn doors"
        , tags: ["Other"
            , "All"]
    }
    , {
        /* Bathroom weith square glass shower  */
        id: 11
        , sourceURL: "https://i.imgur.com/jryqOKK.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Bathroom weith free-standing tub  */
        id: 12
        , sourceURL: "https://i.imgur.com/LRELRvX.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Basement wide shot with half-wall  */
        id: 13
        , sourceURL: "https://i.imgur.com/kgYNALY.jpg"
        , title: "Basement Finish"
        , alt: "Basement finsh image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* FHOP outside shot  */
        id: 14
        , sourceURL: "https://i.imgur.com/sXKRL1z.jpg"
        , title: "Pro Bono"
        , alt: "Chapel exterior image"
        , caption: "Outdoor Chapel at Francis House of Prayer 'pro bono'"
        , tags: ["Other"
            , "All"]
    }
    , {
        /* Bathroom 2-sink unit stone countertop  */
        id: 15
        , sourceURL: "https://i.imgur.com/eWzymkp.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Navy blue walls bathroom */
        id: 16
        , sourceURL: "https://i.imgur.com/dPz5oN4.jpg"
        , title: "Bathroom Remodel"
        , alt: "Bathroom remodel image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* FHOP Chapel interior */
        id: 17
        , sourceURL: "https://i.imgur.com/9sI8Add.jpg"
        , title: "Pro Bono"
        , alt: "Chapel interior image"
        , caption: "Outdoor Chapel at Francis House of Prayer 'pro bono'"
        , tags: ["Other"
            , "All"]
    }
    , {
        /* Finished basement exercise area */
        id: 18
        , sourceURL: "https://i.imgur.com/gA0hc9v.jpg"
        , title: "Basement Finish"
        , alt: "Finshed basement image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Basement Bar side angle */
        id: 19
        , sourceURL: "https://i.imgur.com/JrS9ypp.jpg"
        , title: "Basement Bartop"
        , alt: "Finshed basement image"
        , caption: ""
        , tags: ["Basements"
            , "Bars"
            , "All"]
    }
    , {
        /* Tile shower stall image, inset tile shelf alcoves */
        id: 20
        , sourceURL: "https://i.imgur.com/13SCKVA.jpg"
        , title: "Shower Remodel"
        , alt: "Bathroom remodel image"
        , caption: "Walk-in shower"
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Basement bar front angle */
        id: 21
        , sourceURL: "https://i.imgur.com/0ZgcCyQ.jpg"
        , title: "Basement Bartop"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
            , "Bars"
            , "All"]
    }
    , {
        /* Bathroom double sink 2 mirrors above */
        id: 22
        , sourceURL: "https://i.imgur.com/PMIqJL7.jpg"
        , title: "Bathroom Vanity"
        , alt: "Bathroom vanity image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Finished basement TV hung on wall */
        id: 23
        , sourceURL: "https://i.imgur.com/Wl5uCw7.jpg"
        , title: "Basement Finish"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Basement bedroom */
        id: 24
        , sourceURL: "https://i.imgur.com/u4Oobg1.jpg"
        , title: "Basement Bedroom"
        , alt: "Finished basement bedroom image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Basement with ship lap walls, fireplace and tv */
        id: 25
        , sourceURL: "https://i.imgur.com/IGjBHfr.jpg"
        , title: "Basement lounge"
        , alt: "Finished basement image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Basement nursery */
        id: 26
        , sourceURL: "https://i.imgur.com/ogRBH36.jpg"
        , title: "Basement nursery"
        , alt: "Finished nursery image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Basement kitchen */
        id: 27
        , sourceURL: "https://i.imgur.com/5YUBQGG.jpg"
        , title: "Basement kitchen"
        , alt: "Finished basement kitchen image"
        , caption: ""
        , tags: ["Basements"
            , "homeCarousel"
            , "All"]
    }
    , {
        /* Basement nursery moon lamp */
        id: 28
        , sourceURL: "https://i.imgur.com/7pPWj6p.jpg"
        , title: "Basement nursery"
        , alt: "Finished basement nursery image"
        , caption: ""
        , tags: ["Basements"
            , "All"]
    }
    , {
        /* Somer kitchen */
        id: 29
        , sourceURL: "https://i.imgur.com/NHrFvEz.jpg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Old white illuminated built-in */
        id: 30
        , sourceURL: "https://i.imgur.com/kFzEOOX.jpg"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
            , "All"]
    }
    , {
        /* Old light wood trapezoid cabinet  */
        id: 31
        , sourceURL: "https://i.imgur.com/dYVHPD4.jpg"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
            , "All"]
    }
    , {
        /* Old teal cabinet  */
        id: 32
        , sourceURL: "https://i.imgur.com/rDFweSO.jpg"
        , title: "Built-in cabinetry"
        , alt: "Built-in cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
            , "All"]
    }
    , {
        /* MaGrann bar  */
        id: 33
        , sourceURL: "https://i.imgur.com/z0QIRaL.jpg"
        , title: "Basement Bar"
        , alt: "Basement bar image"
        , caption: ""
        , tags: ["Basements"
            , "Bars"
            , "All"]
    }
    , {
        /* LongKitchen 1  */
        id: 34
        , sourceURL: "https://i.imgur.com/he6gGah.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 2  */
        id: 35
        , sourceURL: "https://i.imgur.com/3K4KfKA.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Built-in TV surround  */
        id: 36
        , sourceURL: "https://i.imgur.com/L71XXj6.jpeg"
        , title: "Custom Cabinetry"
        , alt: "Custom Cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
            , "Basements"
            , "All"]
    }
    , {
        /* Built-in w/castles  */
        id: 37
        , sourceURL: "https://i.imgur.com/b3nM6R2.jpeg"
        , title: "Custom Cabinetry"
        , alt: "Custom Cabinetry image"
        , caption: ""
        , tags: ["Custom Cabinetry"
            , "Basements"
            , "All"]
    }
    , {
        /* Lehman Kitchen 1  */
        id: 38
        , sourceURL: "https://i.imgur.com/Fsr2Prr.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Kitchens"
            , "All"]
    }
    , {
        /* Marble Bathroom 1  */
        id: 39
        , sourceURL: "https://i.imgur.com/mTWvwyb.jpeg"
        , title: "Bathroom Vanity"
        , alt: "Bathroom Vanity image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Marble Bathroom 2  */
        id: 40
        , sourceURL: "https://i.imgur.com/06uqidP.jpeg"
        , title: "Bathroom Shower"
        , alt: "Bathroom Shower image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Marble Bathroom 3  */
        id: 41
        , sourceURL: "https://i.imgur.com/lYRQuax.jpeg"
        , title: "Bathroom"
        , alt: "Bathroom Image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Emerald Bathroom 1  */
        id: 42
        , sourceURL: "https://i.imgur.com/GmsQKW9.jpeg"
        , title: "Bathroom Vanity"
        , alt: "Bathroom Vanity Image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Emerald Bathroom 2  */
        id: 43
        , sourceURL: "https://i.imgur.com/D1s7Ms0.jpeg"
        , title: "Bathroom Shower"
        , alt: "Bathroom Shower Image"
        , caption: ""
        , tags: ["Bathrooms"
            , "All"]
    }
    , {
        /* Brown Kitchen 1  */
        id: 44
        , sourceURL: "https://i.imgur.com/S8xMYZF.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Brown Kitchen 2  */
        id: 45
        , sourceURL: "https://i.imgur.com/tERHDED.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Brown Kitchen 3  */
        id: 46
        , sourceURL: "https://i.imgur.com/qjfb8s6.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Brown Kitchen 4  */
        id: 47
        , sourceURL: "https://i.imgur.com/UYbCTxT.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Grey Kitchen 1  */
        id: 48
        , sourceURL: "https://i.imgur.com/PNhch03.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Grey Kitchen 2  */
        id: 49
        , sourceURL: "https://i.imgur.com/HBvmsE0.jpeg"
        , title: "Kitchen"
        , alt: "Kitchen Image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 3  */
        id: 50
        , sourceURL: "https://i.imgur.com/EPrAKgQ.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 4  */
        id: 51
        , sourceURL: "https://i.imgur.com/RLv7Ya1.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 5  */
        id: 51
        , sourceURL: "https://i.imgur.com/K3BaU2v.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 6  */
        id: 52
        , sourceURL: "https://i.imgur.com/O2IVbia.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 7 */
        id: 53
        , sourceURL: "https://i.imgur.com/dFr96IA.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 8 */
        id: 54
        , sourceURL: "https://i.imgur.com/nMpos6R.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 9 */
        id: 55
        , sourceURL: "https://i.imgur.com/z0OPPdy.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 10 */
        id: 56
        , sourceURL: "https://i.imgur.com/x4SgqSq.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["homeCarousel"
            , "Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 11 */
        id: 57
        , sourceURL: "https://i.imgur.com/0bdh9tQ.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 12 */
        id: 58
        , sourceURL: "https://i.imgur.com/ZP7Ofm7.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* LongKitchen 12 */
        id: 59
        , sourceURL: "https://i.imgur.com/MHPF3YJ.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Paisley Backsplash Kitchen */
        id: 60
        , sourceURL: "https://i.imgur.com/B53LXpP.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 2  */
        id: 61
        , sourceURL: "https://i.imgur.com/EX502Zh.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 3  */
        id: 62
        , sourceURL: "https://i.imgur.com/vnMxen8.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 4  */
        id: 63
        , sourceURL: "https://i.imgur.com/THnayMQ.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 5 */
        id: 64
        , sourceURL: "https://i.imgur.com/WvoAMRw.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 6 */
        id: 65
        , sourceURL: "https://i.imgur.com/wHg4LqA.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 7 */
        id: 66
        , sourceURL: "https://i.imgur.com/IkzRkHv.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }
    , {
        /* Lehman Kitchen 8 */
        id: 67
        , sourceURL: "https://i.imgur.com/QO9Aadk.jpeg"
        , title: "Kitchen Remodel"
        , alt: "Kitchen remodel image"
        , caption: ""
        , tags: ["Kitchens"
            , "All"]
    }

];

export default ImageList;

export function getImagesByTag(searchTag) {
    /* pull back all images matching the requested tag */
    var allImages = ImageList.filter(
        function (imageEntry) {
            return imageEntry.tags.find(tag => tag === searchTag) !== undefined;
        }
    );

    // sort the array in reverse ID order, so that newer photos appear first 
    allImages.sort((a, b) => {
        if (a.id < b.id) {
            return 1;
        }
        else
            return -1;
    });

    //return the sorted array
    return allImages;
};

export function getImageByID(searchID) {
    return (
        ImageList.find(image => image.id === searchID)
    )
};
